<template>
	<b-modal ref="modalIntraLocation" @hidden="resetModal" :size="sizeModal">
		<template slot="modal-header" slot-scope="{ close }">
			<h2 class="mx-auto my-auto">{{ $t('intra_location.ajouter') }}</h2>
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'times-circle']" /></i>
		</template>
		<template slot="default">
			<div class="row">
				<div class="col-12" v-if="form_message !== ''">
				    <ErrorAlert :messageI18n="form_message" />
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<label for="intra_location_parent">{{ $t('intra_location.location_parent') }} *</label>
					<e-select
						v-model="form.intralocation_parent"
						id="intra_location_parent"
						track-by="uuid"
						label="intralocation_label"
						:placeholder="$t('intra_location.rechercher_localisation')"
						:selectedLabel="$t('global.selected_label')"
						:options="intra_locations"
						:searchable="true"
						:show-labels="false"
						:allow-empty="false"
						class="form-group"
						:class="{ 'is-invalid': errors && errors.indexOf('no parent') > -1 }"
					>
						<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.intralocation_label }}</span></template>
					</e-select>
				</div>

				<div class="col-12">
					<div class="form-group">
						<label for="libelle">{{ $t('intra_location.location_label') }}</label>
						<input
							id="libelle"
							type="text"
							class="form-control"
							:placeholder="$t('intra_location.location_label')"
							:class="{ 'is-invalid': errors && errors.indexOf('no label') > -1 }"
							v-model="form.intralocation_label"
						>
					</div>
				</div>

				<div class="col-12">
					<label for="intra_location_type">{{ $t('intra_location.location_type') }}</label>
					<e-select
						v-model="form.intralocation_type"
						id="intra_location_type"
						track-by="code"
						label="label"
						:placeholder="$t('intra_location.rechercher_type')"
						:selectedLabel="$t('global.selected_label')"
						:options="types"
						:searchable="true"
						:show-labels="false"
						:allow-empty="false"
						:class="{ 'is-invalid': errors && errors.indexOf('no type') > -1 }"
						class="form-group"
					/>
				</div>

				<div class="col-12">
					<div class="form-group row">
						<div class="col-6">
							<label for="size">{{ $t('intra_location.taille') }}</label>
							<b-form-input 
								id="size" 
								type="text" 
								number
								v-model="form.intralocation_size"
								:placeholder="$t('intra_location.taille')"
							></b-form-input>
						</div>
						<div class="col-6">
							<label for="nb_horses">{{ $t('intra_location.nb_horses') }}</label>
							<b-form-input 
								id="nb_horses" 
								type="text" 
								number
								v-model="form.intralocation_horses"
								:placeholder="$t('intra_location.taille')"
							></b-form-input>
						</div>
					</div>
				</div>

				<template v-if="form.intralocation_parent && form.intralocation_parent.intralocation_img_url">
					<div class="col-12" ref="img_wrapper">
		                <label class="d-block">{{ $t('lieu.localisation_parent') }} :</label>
		                <a href="#" class="d-block" @click.prevent="clearShape"><font-awesome-icon :icon="['fal', 'times']" /> {{ $t('lieu.clear_shape') }}</a>
						<img ref="parent_img" width="100%" :src="form.intralocation_parent.intralocation_img_url" class="d-none" @load="onLoadImg">
						<canvas role="button" ref="canvas" @click="clickCanvas">
						</canvas>
					</div>
					<div class="col-12">
	                	<div class="form-group">
							<label for="text_color" class="col-form-label">{{ $t("contact.color") }}</label>
							<b-form-input type="color" id="text_color" v-model="text_color"></b-form-input>
						</div>
					</div>
				</template>

				<div class="col-12">
		            <div class="form-group">
		                <label>{{ $t('lieu.image_localisation') }} :</label>
		                <b-form-file
		                    v-model="image_file"
		                    :accept="type_file_accepted_img"
		                    :placeholder="placeholderFormatted"
		                    :drop-placeholder="$t('fichier.drop_files')+'...'"
		                ></b-form-file>
		            </div>
		        </div>
			</div>
		</template>

		<template slot="modal-footer" class="justify-content-center">
			<b-button variant="primary" @click="saveForm()" :disabled="processing">
				<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
				{{ $t('global.enregistrer') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script type="text/javascript">
    import Common from '@/assets/js/common.js'
	import IntraLocation from '@/mixins/IntraLocation'
	import _cloneDeep from 'lodash/cloneDeep'

	export default {
		name: 'AddModal',
		props: {
			intraLocationId: { type: Number, default: 0 }
		},
		mixins: [IntraLocation],
		data () {
			return {
				intralocation_id: 0,
				processing: false,
				form_message: '',
				intra_locations: [],
				types: [],
				errors: [],

				form: null,
				default_form: {
					intralocation_label	  : '',
					intralocation_horses  : 0,
					intralocation_type    : null,
					intralocation_size    : 0,
					intralocation_parent  : null,
					intralocation_img_url : null
				},

				fill_canvas: [],
				text_color: '#ffffff',
                image_file: null,
                type_file_accepted_img: "image/jpeg, image/jpg, image/png",
                image_loaded: false
			}
		},

		created() {
			this.preinit_component()
		},

		methods: {
			async preinit_component() {
				this.resetModal()

				// On récupére les résidences et localisations internes liées
				this.getIntraLocationsWithresidences(true, false)
					.then(res => (this.intra_locations = res))

				this.getIntraLocationTypes()
					.then(res => (this.types = res))
			},

			async saveForm() {
				if(!this.processing)
				{
					this.errors = []
					this.form_message = ''

					if(!this.form.intralocation_label) {
						this.form_message = 'intra_location.need_label'
						this.errors.push('no label')
					}
					if(!this.form.intralocation_type) {
						this.form_message = 'intra_location.need_type'
						this.errors.push('no type')
					}

					if(!this.form.intralocation_parent) {
						this.form_message = 'intra_location.need_parent'
						this.errors.push('no parent')
					}

					// A la moindre erreur observée, on skip
					if(this.form_message != '') return
					this.processing = true

					let temp = _cloneDeep(this.form)
					temp.intralocation_type = this.form.intralocation_type.id
					temp.intralocation_parent  = this.form.intralocation_parent.intralocation_id
					temp.intralocation_is_root = this.form.intralocation_parent.intralocation_is_root
					if(this.fill_canvas.length > 0) {
						temp.intralocation_shape = JSON.stringify({fill: this.fill_canvas})
						temp.intralocation_shape = JSON.stringify({fill: this.fill_canvas, color: this.text_color})
					}
					else {
						temp.intralocation_shape = null
					}
					
					temp.old_intralocation = {
						intralocation_horses: 0,
						intralocation_size: 0
					}

					if(this.intralocation_id == 0) {
						temp = await this.addIntraLocation(temp)
					}
					else {
						temp.intralocation_id = this.intralocation_id
						await this.updateIntraLocation([temp])
					}

	                if(this.image_file && this.image_file.size > 0 && window.navigator.onLine) {
						let intralocation_id = temp.intralocation_id
	                    if(intralocation_id < 0) {
	                        await this.$sync.force(true, true)
	                        intralocation_id = this.$sync.replaceWithReplicated('intra_location', intralocation_id)
	                    }
	                    await this.uploadImageIntraLoc(intralocation_id, this.image_file)
	                    await this.$sync.force(true, true)
	                }
					
					this.processing = false
					this.successModal()
				}
			},

			openModal(location=null) {
				if(location) {
					this.intralocation_id = location.intralocation_id
					this.form.intralocation_label	= location.intralocation_label
					this.form.intralocation_horses  = location.intralocation_horses
					this.form.intralocation_type    = location.intralocation_type
					this.form.intralocation_size    = location.intralocation_size
					this.form.intralocation_img_url = location.intralocation_img_url
					this.form.intralocation_parent  = this.intra_locations.find(il => il.intralocation_id == location.intralocation_parent)
					if(location.intralocation_shape && typeof location.intralocation_shape == 'string') {
						location.intralocation_shape = JSON.parse(location.intralocation_shape)
					}
					this.fill_canvas = location.intralocation_shape ? location.intralocation_shape.fill : []
					this.text_color = location.intralocation_shape ? location.intralocation_shape.color : '#ffffff'
				}

				this.$refs.modalIntraLocation.show()
			},

			resetModal() {
				this.form = _cloneDeep(this.default_form)
				this.intralocation_id = 0

				this.fill_canvas = []
				this.text_color = '#ffffff'
                this.image_file = null
				this.image_loaded = false

				if(this.intra_locations.length > 0)
					this.form.intralocation_parent = this.intra_locations[0]
			},

			successModal() {
				this.$emit('ok')
				this.$refs.modalIntraLocation.hide()
				this.preinit_component()
			},

			onLoadImg() {
				this.image_loaded = true
				this.drawShape()
			},

			loadImg() {
				const ctx = this.$refs.canvas.getContext("2d");
				this.$refs.canvas.width = this.$refs.img_wrapper.clientWidth - 40
				this.$refs.canvas.height = this.$refs.parent_img.height
			  	ctx.drawImage(this.$refs.parent_img, 10, 10);
			},

			clickCanvas(event) {
				const rect = this.$refs.canvas.getBoundingClientRect()
    			const x = event.clientX - rect.left
    			const y = event.clientY - rect.top

    			this.fill_canvas.push([x, y])

    			this.drawShape(x, y)
			},

			drawShape(x = null, y = null) {
				if(!this.$refs.canvas) return false
    			const ctx = this.$refs.canvas.getContext("2d")
    			ctx.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height)

    			this.loadImg()

				if(!this.image_loaded || this.fill_canvas.length == 0) return false

    			ctx.beginPath();

				ctx.moveTo(this.fill_canvas[0][0], this.fill_canvas[0][1])
    			this.fill_canvas.forEach(path => {
					ctx.lineTo(path[0], path[1])
    			})
				ctx.lineTo(this.fill_canvas[0][0], this.fill_canvas[0][1])

    			ctx.fillStyle = '#ffffff'
    			ctx.strokeStyle = '#ffffff'
    			ctx.globalAlpha = 0.5

				ctx.fill();
				ctx.stroke();

				if(x && y) {
    				ctx.beginPath();
					ctx.arc(x, y, 5, 0, 2 * Math.PI);
					ctx.fill();
				}
			},

			clearShape() {
				this.fill_canvas = []
				this.drawShape()
			}
		},

		computed: {
			sizeModal() {
				return (this.form.intralocation_parent && this.form.intralocation_parent.intralocation_img_url) ? 'xxl' : ''
			},
			placeholderFormatted(){
				//eslint-disable-next-line
				return this.form.intralocation_img_url ? this.form.intralocation_img_url.match(/([^\/]+$)/)[0] : this.$t('invoice.devis.choisir_document')
			}
		},

		watch: {
			shape_color() {
				this.drawShape()
			}
		},

		components: {
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
		}
	}

</script>